import { Link } from "@StarberryUtils"
import React from "react";
import {Row, Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import { GetURL } from "../components/common/site/functions";
import { Header } from "../components/Header/Header";
import { Footer } from "../components/Footer/Footer";


const Sitemap = (props) => {
    const data = useStaticQuery(graphql`
        query{
            glstrapi {
                menuBurgers(where: {Publish: true}, sort: "Sort") {
                    _id
                    Parent_Label
                    Parent_Link {
                        _id
                    }
                    Sub_Menus {
                        Label
                        Link {
                            _id
                        }
                        Add_Sub_Menus {
                            Sub_Menu_Label
                            Sub_Menu_Link {
                                _id
                                URL
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <div className="wrapper wrapper-static sitemap">
            <Header/>
            <Container className="wrapper-sitemap-main-list">
                <Row>
                    <div className="content my-5">
                        <h2>Sitemap</h2>
                        <ul className="sitemap-main-list">
                            {data.glstrapi.menuBurgers && data.glstrapi.menuBurgers.map((node, i) => {
                                    let url = GetURL(node.Parent_Link._id)
                                    return <>
                                        {node.Sub_Menus.length === 0 &&
                                            <li key={i}><Link  to={`/${url}`}>{node.Parent_Label}</Link></li>
                                        }
                                        {node.Sub_Menus.length !== 0 &&
                                            <li key={i}>
                                                {(url == "about" || url == "rent") ? <a style={{ pointerEvents: "none"}}>{node.Parent_Label}</a> : <Link to={`/${url}`}>{node.Parent_Label}</Link>}
                                                <ul className="sub-menu sitemap-sub-menu-list">
                                                    {node.Sub_Menus.map((Sub_Menus, i) => {
                                                        let sub_menu_url = GetURL(Sub_Menus.Link._id)
                                                        return (
                                                            <li>
                                                                <Link to={`/${sub_menu_url}`}>{Sub_Menus.Label}</Link>
                                                                <ul className="level-2">
                                                                    {Sub_Menus.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                                                                        return <li key={i}>
                                                                             <Link to={`/about/local-living`}>{Add_Sub_Menus.Sub_Menu_Label}</Link>
                                                                            {/* {Add_Sub_Menus.Sub_Menu_Label} */}
                                                                        </li>
                                                                    })}
                                                                </ul>
                                                            </li>)
                                                        })
                                                    }
                                            </ul>
                                        </li>
                                    }
                                </>
                            })}
                        </ul>
                    </div>
                </Row>
            </Container>
            <Footer search="Popular_Search_About"/>
        </div>
    )
}


export default Sitemap